import React from "react";
import PropTypes from 'prop-types';

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import { withStyles } from "@mui/styles";
import { useTheme, withTheme } from '@emotion/react';


import defaultMetadata from '../../defaultMetadata';

const styles = {
    subGrid: {
        width: "auto",
    },
    sectionContainer: {
        width: "100%",
        padding: 24,
        boxSizing: "border-box",
    },
    sectionHeading: {
        fontWeight: 900,
        lineHeight: 1.5,
        textTransform: "uppercase",
        color: defaultMetadata.theme.colors.charcoalGrey,
    },
    greenLine: {
        height: 2,
        width: 16,
        marginTop: 4,
        marginBottom: 16,
    },
    actionLink: {
        display: "inline-block",
        fontSize: 14,
        lineHeight: 1,
        fontWeight: 600,
        color: defaultMetadata.theme.colors.turquoise,

        "&:hover": {
            textDecoration: "underline",
        }
    }
};

class Section extends React.Component {
    render() {
        const {children, classes, sectionHeading, action} = this.props;
        let actionItem = null;
        if (action) {
            actionItem = (
                <a href={action.url} className={classes.actionLink} style={{color: this.props.theme.palette.primary.main}}>
                    {action.name}
                </a>
            );
        }

        return (
            <Paper elevation={1} className={classes.sectionContainer} style={this.props.style}>
                <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
                    {/* <Grid container item direction="column" className={classes.subGrid}> */}
                        <Grid item>
                          <span className={classes.sectionHeading}>
                            {sectionHeading}
                          </span>
                        </Grid>
                    {/* </Grid> */}
                    <Grid item justifyContent="center" alignItems="center">
                        {actionItem}
                    </Grid>
                </Grid>
                <div className={classes.greenLine} style={{background: this.props.theme.palette.primary.main}}/>
                {children}
            </Paper>
        );
    }
}


export default withStyles(styles, {withTheme: true})(withTheme((Section)));