import React, { useCallback } from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import classnames from "classnames";
import defaultMetadata from "../../defaultMetadata";
import { useTheme, withTheme } from "@emotion/react";
import classNames from "classnames";

const DropItem = () => <></>;

const styles = (theme) => {
    return {
        headerRoot: {
            background: "#fff",
        },
        headerContent: {
            maxWidth: 1280,
            margin: "auto",
            height: 100,
            padding: "0 16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            [theme.breakpoints.down(720)]: {
                padding: "0 16px",
            },
            [theme.breakpoints.down(1281)]: {
                maxWidth: 1120,
                padding: "0px 24px",
            },
        },
        headerLinks: {
            textTransform: "uppercase",
            color: "#3a3f42",
        },
        logo: {
            maxHeight: 64,
            // marginRight: 24,
            [theme.breakpoints.down('sm')]:{
                maxHeight: 48,
            }
        },
        titleContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
        networkTitle: {
            fontWeight: 700,
            fontSize: 24,
        },
        titleCaption: {
            fontSize: 16,
            lineHeight: "1.5em",
        },
        menuDrawerContainer: {
            width: 200,
        },
        menuIcon: {
            cursor: "pointer",
        },
        menuLink: {
            color: defaultMetadata.theme.colors.battleshipGrey,
        },
        menuLinkExpanded: {
            marginLeft: 16,
            marginRight: 16,
        },
    };
};

const Header = (props) => {
    const renderLogoAndTitle = () => {
        const { classes } = props;
        const { logo, name, header_navigation_url } = defaultMetadata;

        return (
            <React.Fragment>
                <div>
                    <a
                        href={
                            header_navigation_url
                                ? header_navigation_url
                                : "/index.html"
                        }
                    >
                        <img className={classes.logo} src={logo} alt="logo" />
                    </a>
                </div>
            </React.Fragment>
        );
    };

    const renderMenuAndLoginExpanded = () => {
        const { classes } = props;
        const {
            mainMenuItems,
            networkBaseUrl,
            multipleInstitutes,
            instituteList,
        } = defaultMetadata;
        let temp = this;
        const menuItemComponents = mainMenuItems.map((menuItem, index) => {
            return (
                <div
                    key={index}
                    style={{ marginLeft: 36, textTransform: "uppercase" }}
                >
                    <a
                        href={new URL(menuItem.url, networkBaseUrl).href}
                        target="_blank"
                        className={classNames(
                            "text_subtitle_big",
                            "ac_link",
                            classes.headerLinks
                        )}
                    >
                        {menuItem.name}
                    </a>
                </div>
            );
        });
        const loginComponent = (
            <div key="login" style={{ marginLeft: 60 }}>
                <Button href={networkBaseUrl} style={{ padding: "8px 16px" }}>
                    Login/Signup
                </Button>
            </div>
        );
        const loginComponentMult = null;

        return (
            <React.Fragment>
                {/* {menuItemComponents} */}
                {multipleInstitutes ? loginComponentMult : loginComponent}
            </React.Fragment>
        );
    };

    const { classes } = props;
    return (
        <div className={classes.headerRoot}>
            <div className={classes.headerContent}>
                <div
                    className="default_flex_container"
                    style={{ width: "100%", justifyContent: "space-between" }}
                >
                    <div
                        style={{ display: "flex" }}
                        className="default_flex_container"
                    >
                        {renderLogoAndTitle()}
                    </div>
                    <div className="default_flex_container">
                        {renderMenuAndLoginExpanded()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(withTheme(Header));
