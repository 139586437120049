import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import metadata from "../../defaultMetadata";
import SearchAndNetworkImage from "../../assets/SearchAndNetworkWithAlums.png";
import Arrow from "../../assets/Arrow.png";
import classNames from "classnames";

const styles = (theme) => {
    return {
        searchAndNetworkContainer: {
            width: "100%",
            position: "relative",
        },
        searchAndNetworkImage: {
            width: "100%",
            // height: 400,
        },
        searchAndNetworkContent: {
            position: "absolute",
            top: "85px",
            left: "103px",
            inset: 0,
            margin: 'auto',
            width: '100%',
            maxWidth: '1100px',
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            flexWrap: "wrap",            
            "& h1.heading-main": {
                fontSize: "32px",
                fontWeight: 900,
                color: "white",
            },
            "& h1.heading-secondary": {
                fontSize: "32px",
                fontWeight: 400,
                color: "white",
            },
        },
        loginButton: {
            padding: "13px 115px",
            fontSize: "18px",
        },
        buttonsGrid: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "24px",
            marginLeft: "158px",
            [theme.breakpoints.down("md")]: {
                gridTemplateColumns: "1fr",
                margin: "24px auto 0px",
            },
        },
        gridButton:{
            padding: '0px 35px',
            height: '80px',
            fontWeight: 700,
            color: 'white',
            border: '1px solid white',                 
            boxSizing: 'border-box',
            transition: 'all 400ms ease',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
,            "&:hover":{
                backgroundColor: '#FF9E1B',
                borderColor: '#FF9E1B',
            }
        }

    };
};

const SearchAndNetwork = (props) => {
    const { classes } = props;
    return (
        <div className={classes.searchAndNetworkContainer}>
            <img
                className={classes.searchAndNetworkImage}
                src={SearchAndNetworkImage}
                alt=""
            />
            <div className={classes.searchAndNetworkContent}>
                <div style={{ maxWidth: 360 }}>
                    <h1 className="heading-main">Search And Network</h1>
                    <h1 className="heading-secondary">
                        with alumni from across the world
                    </h1>
                    <Button
                        href={metadata.networkBaseUrl}
                        style={{
                            padding: "13px 115px",
                            fontWeight: 700,
                            fontSize: "18px",
                            marginTop: "12px",
                        }}                        
                    >
                        Login/Signup
                    </Button>
                    <div
                        className="default_flex_container"
                        style={{ marginTop: "16px" }}
                    >
                        <div
                            className="text_body"
                            style={{
                                color: "white",
                                marginRight: "2px",
                                fontWeight: 400,
                                letterSpacing: "0.7px",
                                textDecoration: 'underline'
                            }}
                        >
                            See qualifications for joining the alumni network
                        </div>
                        <img src={Arrow} width="20px" />
                    </div>
                </div>
                <div className={classes.buttonsGrid}>
                    <div className={classNames(classes.gridButton)}>                        
                        Explore Communities
                    </div>
                    <div className={classNames(classes.gridButton)}>                        
                        Learn for A Lifetime
                    </div>
                    <div className={classNames(classes.gridButton)}>
                        Share News
                    </div>
                    <div className={classNames(classes.gridButton)}>
                        Innovate Together
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(SearchAndNetwork);
