import React, { useCallback } from 'react';

// import URL from 'url';

import Grid from '@mui/material/Grid';
import { withStyles } from "@mui/styles";
import classnames from "classnames";
import defaultMetadata from "../../defaultMetadata";
import { useTheme, withTheme } from '@emotion/react';
import twitter from '../../assets/syneos_social/twitter.png'
import insta from '../../assets/syneos_social/insta.png'
import youtube from '../../assets/syneos_social/youtube.png'
import fb from '../../assets/syneos_social/fb.png'
import alma from '../../assets/alma.png'


const DropItem = () => <></>

const styles = (theme) =>{
    return {
        footer: {
            background: "#3A3F42",
            color: '#fff',
            padding: '44px 64px',
            [theme.breakpoints.down('md')]:{
                padding: '40px 24px'
            }
        },
        logosItem:{
            [theme.breakpoints.down('md')]:{
                alignSelf: 'flex-start'
            }
        },
        email_link:{
            "&:hover":{
                textDecoration: "underline"
            }
        }
    }
};


const Footer = (props) => {

    const logoComponent = () => {
        const {logo, footer_address_name} = defaultMetadata;
        return (
            <React.Fragment>
                <Grid container item xs={12} sm={4} md={3} direction="column" alignItems="right">
                    {/* <div className="text_title" style={{color: '#FF9E1B', marginBottom: '16px'}}>ABOUT US</div> */}
                    {/* <Grid item> */}
                        <a href="https://www.syneoshealth.com/" style={{marginBottom: 20, display: "block"}}><img style={{maxWidth: '156px'}} src={logo} alt="Logo"/></a>
                        {/* <div style={{marginBottom: 8, maxWidth: '320px'}}>{footer_address_name}</div> */}
                    {/* </Grid> */}
                </Grid>
            </React.Fragment>
        );
    }

    const quick_links = () => {
        return(
            <div></div>
        );
    }

    const {classes} = props;
    const {footer_links} = defaultMetadata;
    return (
        <React.Fragment>
            <div style={{background: '#EB3300', height: '16px'}}></div>
            <div className={classes.footer+' text_title_small'}>
                <Grid container justifyContent="">
                    {logoComponent()}
                    <Grid container item xs={12} sm={8} md={6} direction="row">
                        <Grid item xs style={{fontSize: 16, fontWeight: 600}}>
                            <div className="text_title" style={{color: '#FF9E1B', marginBottom: '8px'}}>CONTACT US</div>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={12} sm={6} md={6}>                              
                                    <div>
                                        <div style={{fontWeight: 800}}>Syneos Health Consulting Alumni</div>
                                        <a href="mailto:sm_alumni@syneoshealth.com" className={classes.email_link} style={{marginTop: "4px"}}>sm_alumni@syneoshealth.com</a>
                                    </div>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={3} direction="column" alignItems="flex-end" style={{fontSize: 14, fontWeight: 600}}>
                        <Grid item className={classes.logosItem}>
                            <div>
                                <a href="https://www.facebook.com/SyneosHealth/" target="_blank"><img style={{width: '24px', marginRight: 24}} src={fb} alt="Logo"/></a>
                                <a href="https://twitter.com/syneoshealth" target="_blank"><img style={{width: '24px', marginRight: 24}} src={twitter} alt="Logo"/></a>
                                <a href="https://www.instagram.com/syneoshealth/?hl=en" target="_blank"><img style={{width: '24px', marginRight: 24}} src={insta} alt="Logo"/></a>
                                <a href="https://www.youtube.com/c/SyneosHealth" target="_blank"><img style={{width: '24px'}} src={youtube} alt="Logo"/></a>
                            </div>
                            <div style={{marginTop: '24px', display: 'flex'}}>
                                <div style={{marginRight: '10px', padding: '15px 0px'}}>Powered by</div>
                                <img style={{width: '100px'}} src={alma} alt="Logo"/>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );

}

export default withStyles(styles, {withTheme: true})(withTheme((Footer)));
