import { Avatar, Button, Grid, Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import { join } from "lodash";
import React from "react";
import Section from "../snippets/section";
import Arrow from "../../assets/Arrow.png";

const styles = (theme) => {
    return {
        backgroundSetter: {
            background: "#F8F8F8",
        },
        findJobsContainer: {
            padding: "66px 0px",
            margin: "auto",
            maxWidth: 1100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 63,
        },
        sectionContainer: {
            width: "100%",
            padding: 24,
            boxSizing: "border-box",
        },
        jobsContainer: {
            maxHeight: "410px",
            overflow: "auto",
            // REMOVE SCROLLBARS
            // "scrollbar-width": 'none',
            // "-ms-overflow-style": "none",
            // "&::-webkit-scrollbar":{
            //     display: 'none'
            // }
        },
        singleJobContainer: {
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
            borderRadius: "3px",
            padding: 24,
            width: "calc(100% - 60px)",
            margin: "8px 2px",
        },
        fa_ellipse: {
            borderRadius: "12px",
            padding: "6px 12px",
            border: "1px solid #D8D8D8",
            marginBottom: "8px",
            marginRight: "3px",
        },
        headingBold: {
            fontSize: "32px",
            fontWeight: 900,
        },
        headingNormal: {
            fontSize: "32px",
            fontWeight: 400,
        },
        findJobsButton: {
            marginTop: "24px",
            padding: "13px 127px",
        },
    };
};
const JobBox = (props) => {
    const { classes } = props;
    const renderSingleJob = (job) => {
        return (
            <div className={classes.singleJobContainer}>
                <div className="default_flex_container">
                    <Avatar>
                        <img
                            src={job.user.image.url}
                            style={{ maxWidth: 50 }}
                        />
                    </Avatar>
                    <div style={{ marginLeft: 8 }}>
                        <div className="text_caption_bold">
                            <span>{job.user.name}</span>
                            <span
                                className="text_caption_small_light"
                                style={{ marginLeft: 5 }}
                            >
                                {job.user.course?.name
                                    ? job.user.course?.name + "'"
                                    : ""}
                                {job.user.batch_small}
                            </span>
                        </div>
                        <div className="text_caption_light">
                            <span>{job.title}</span>
                        </div>
                    </div>
                </div>
                <div className="text_title" style={{ marginTop: "18px" }}>
                    {job.subject}
                </div>
                <div
                    className="default_flex_container"
                    style={{ marginTop: 8 }}
                >
                    {job.functional_areas.map((fa) => {
                        return (
                            <div
                                className={classNames(
                                    classes.fa_ellipse,
                                    "text_caption_light"
                                )}
                            >
                                {fa}
                            </div>
                        );
                    })}
                    {job.min_experience ? (
                        <div
                            className={classNames(
                                classes.fa_ellipse,
                                "text_caption_light"
                            )}
                        >
                            {job.min_experience}
                            {job.max_experience
                                ? ` - ${job.max_experience} Yrs Exp.`
                                : "+ Yrs Exp."}
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };
    const renderJobs = () => {
        return (
            <div className={classes.jobsContainer}>
                {allJobs.map((job) => {
                    return renderSingleJob(job);
                })}
            </div>
        );
    };    

    return (
        <div className={classes.backgroundSetter}>
            <div className={classes.findJobsContainer}>
                <Paper
                    className={classes.sectionContainer}
                    style={{ flexBasis: 588 }}
                >
                    {renderJobs()}
                </Paper>
                <div style={{ flexBasis: "462px" }}>
                    <div>
                        <span className={classes.headingBold}>Find Jobs</span>
                        <span className={classes.headingNormal}>
                            {" "}
                            at Syneos & Alumni Companies
                        </span>
                    </div>
                    <div
                        className="text_body_big_light"
                        style={{ marginTop: "12px", fontSize: "18px" }}
                    >
                        Connect with alumni in different industries across the
                        world to expand your professional network.
                    </div>
                    <Button
                        style={{ marginTop: "24px", padding: "13px 127px", fontSize: '18px'}}
                    >
                        <span>See All Jobs</span>
                        <img
                            src={Arrow}
                            style={{
                                width: "24px",
                                position: "absolute",
                                right: "26px",
                            }}
                        />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(JobBox);

const allJobs = [
    {
        id: "620df62b4fd6b30011112a73",
        subject: "PM @test company, New London",
        title: "PM",
        functional_areas: ["Software Engineering"],
        locations: [
            {
                id: "5264455",
                name: "New London",
            },
        ],
        min_experience: 4,
        max_experience: 9,
        created_at: "2022-02-17T12:45:55.225+05:30",
        company: {
            id: "52afcac25f4fb6468300052c",
            name: "test company",
        },
        normalized_titles: [],
        user: {
            id: "6108cd8d65fcbd3efd1bb858",
            is_official: null,
            name: "Kevoco",
            user_source: "registered_user",
            facebook_profile_url: null,
            linkedin_profile_url: null,
            twitter_profile_url: null,
            github_profile_url: null,
            instagram_profile_url: null,
            behance_profile_url: null,
            stack_overflow_profile_url: null,
            dribble_profile_url: null,
            profile_title: null,
            headline: null,
            current_slug: "kevoco",
            certified: false,
            emails: ["kevoco9593@flipssl.com"],
            image: {
                id: null,
                url: "https://d26pkxyw9vw39t.cloudfront.net/assets/default/profile/profile-228a568cfcf223e569868fc693635d73663d1a479d819d52cdf62417439d8ec0.png",
                filepath: null,
                content_type: null,
                name: null,
            },
            profile_url: "/profiles/kevoco",
            url: "/profiles/kevoco",
            connected_institute_names: [
                "Brooklyn 99 institute of conscience and chronology",
            ],
            connected_institute_titles: ["B99"],
            biolet_id: "6108cdac2d7bce0013ed0a16",
            biolet_type: "Biolets::Education",
            institute_type: "Institution::College",
            from_year: 2017,
            to_year: 2020,
            batch: "2020",
            batch_small: "20",
            course: {
                id: "60c760c76cf82800143c9322",
                name: "Jimmy Jabs",
            },
            user_type: "alumni",
            external_id: "1ca",
            prospect_status: null,
            gift_officer_name: null,
            gift_officer_email: null,
            entity_mapped: false,
        },
        institute: {
            id: "6009af8a5a6fe60015e0070b",
            title: "B99",
            name: "Brooklyn 99 institute of conscience and chronology",
        },
    },
    {
        id: "62027c7e17055d0010f82392",
        subject: "Sales/Business Development Manager @AlmaConnect, Gurgaon",
        title: "",
        functional_areas: ["Sales / Business"],
        locations: [
            {
                id: "1270642",
                name: "Gurgaon",
            },
        ],
        min_experience: 3,
        max_experience: 5,
        created_at: "2022-02-08T19:51:50.561+05:30",
        company: {
            id: "515ade113fc3d53d0e000032",
            name: "Alma Connect",
        },
        normalized_titles: [
            {
                id: "5b8e5b2bc0521c00072ddfe4",
                name: "sales manager",
            },
            {
                id: "5b8e5b33a4f6340007cf62b5",
                name: "business development manager",
            },
        ],
        user: {
            id: "5e26d286b6e087000ea0d05d",
            is_official: null,
            name: "Rashi Khandelwal",
            user_source: "registered_user",
            facebook_profile_url: "https://www.facebook.com/10209464582708387",
            linkedin_profile_url:
                "https://www.linkedin.com/in/rashi-khandelwal-b7855366",
            twitter_profile_url: null,
            github_profile_url: null,
            instagram_profile_url: null,
            behance_profile_url: null,
            stack_overflow_profile_url: null,
            dribble_profile_url: null,
            profile_title: "Business Operations/HR Specialist",
            headline: "Business Operations/HR Specialist at AlmaConnect",
            current_slug: "rashi-khandelwal-21",
            certified: false,
            emails: ["khandelwal.rashi90@gmail.com"],
            currently_at: {
                id: "5620c8fec670e66eec0010fe",
                name: "AlmaConnect",
                url: "/alumni/company/5620c8fec670e66eec0010fe",
            },
            current_city: {
                id: "1270642",
                name: "Gurgaon",
                url: "/alumni/location/1270642",
            },
            image: {
                id: "5f86a68e10b28d00150ee6ca",
                url: "https://d3sr7cc30ek8f4.cloudfront.net/images/size:1500x1500/type:cover/prod/5f86a68e10b28d00150ee6ca/Rashi-Profile-Pic.png",
                filepath: "prod/5f86a68e10b28d00150ee6ca/Rashi-Profile-Pic.png",
                content_type: "image/png",
                name: "Rashi-Profile-Pic.png",
            },
            profile_url: "/profiles/rashi-khandelwal-21",
            url: "/profiles/rashi-khandelwal-21",
            connected_institute_names: [
                "Shanti Business School",
                "Alma Connect",
            ],
            connected_institute_titles: [
                "Shanti Business School",
                "Alma Connect",
            ],
            biolet_id: "5e26d2a7b6e087000ea0d0ec",
            biolet_type: "Biolets::Education",
            institute_type: "Institution::College",
            from_year: 2012,
            to_year: 2014,
            batch: "2014",
            batch_small: "14",
            course: {
                id: "563a5d9d81baca3d9e001619",
                name: "PGDM Full Time-(FT)",
            },
            branch: {
                id: "563c767cc670e6752900019d",
                name: "Human Resources",
            },
            user_type: "alumni",
            external_id: null,
            prospect_status: null,
            gift_officer_name: null,
            gift_officer_email: null,
        },
        institute: {
            id: "515ade113fc3d53d0e000032",
            title: "Alma Connect",
            name: "Alma Connect",
        },
    },
    {
        id: "61eed088d8a74800112ceef5",
        subject:
            "Digital Commerce Consultant @Thoucentric Tech Pvt. Ltd, Bangalore",
        title: "Digital Commerce Consultant",
        functional_areas: ["Consulting"],
        locations: [
            {
                id: "1277333",
                name: "Bangalore",
            },
        ],
        min_experience: 4,
        max_experience: 8,
        created_at: "2022-01-24T21:45:04.941+05:30",
        company: {
            id: "5ea989b8c3ba5d001207e31c",
            name: "Thoucentric Tech Pvt. Ltd",
        },
        normalized_titles: [
            {
                id: "5e5f254f73535b000770420b",
                name: "digital commerce functional consultant",
            },
        ],
        user: {
            id: "5de3f6681fd2ab0013b7a402",
            is_official: null,
            name: "Padmapriya Srinivasan",
            user_source: "registered_user",
            facebook_profile_url: null,
            linkedin_profile_url:
                "https://www.linkedin.com/in/padmapriya-s-0b5a5012",
            twitter_profile_url: null,
            github_profile_url: null,
            instagram_profile_url: null,
            behance_profile_url: null,
            stack_overflow_profile_url: null,
            dribble_profile_url: null,
            profile_title: "Ecom Program Management",
            headline: "Ecom Program Management",
            current_slug: "padmapriya-srinivasan",
            certified: false,
            emails: ["2019mc21040@wilp.bits-pilani.ac.in"],
            current_city: {
                id: "1277333",
                name: "Bangalore",
                url: "/alumni/location/1277333",
            },
            image: {
                id: "61bd104f5d87300013dab45e",
                url: "https://d3sr7cc30ek8f4.cloudfront.net/images/size:1500x1500/type:cover/prod/61bd104f5d87300013dab45e/1547664432103.jpeg",
                filepath: "prod/61bd104f5d87300013dab45e/1547664432103.jpeg",
                content_type: "image/jpeg",
                name: "1547664432103.jpeg",
            },
            profile_url: "/profiles/padmapriya-srinivasan",
            url: "/profiles/padmapriya-srinivasan",
            connected_institute_names: [
                "Birla Institute of Technology & Science",
            ],
            connected_institute_titles: ["BITS Pilani"],
            biolet_id: "5de3f6a0e5b62c0011c210d0",
            biolet_type: "Biolets::Education",
            institute_type: "Institution::College",
            from_year: 2019,
            to_year: 2021,
            batch: "2021",
            batch_small: "21",
            campus: {
                id: "5bd6d557991c7c0017bae42b",
                name: "WILP",
            },
            course: {
                id: "563a5d2c81baca3d9e000fe6",
                name: "M.Sc",
            },
            branch: {
                id: "563c76e9c670e67529000bfe",
                name: "Business Analytics",
            },
            user_type: "alumni",
            external_id: "2019MC21040",
            prospect_status: null,
            gift_officer_name: null,
            gift_officer_email: null,
            entity_mapped: true,
        },
        institute: {
            id: "4fb42e8eaddf32296f010ffc",
            title: "BITS Pilani",
            name: "Birla Institute of Technology & Science",
        },
    },
    {
        id: "61a0d87a4e173200139a8ffc",
        subject: "Head - Operations @ePaylater, Mumbai",
        title: "Head - Operations",
        functional_areas: ["Operations"],
        locations: [
            {
                id: "1275339",
                name: "Mumbai",
            },
        ],
        min_experience: 8,
        max_experience: 10,
        created_at: "2021-11-26T18:22:10.850+05:30",
        company: {
            id: "584524030907f408ae0083e6",
            name: "ePaylater",
        },
        normalized_titles: [
            {
                id: "5b8e5b2ac515cf00075ab1b7",
                name: "operations head",
            },
        ],
        user: {
            id: "61a0b115224ed10014051da7",
            is_official: null,
            name: "Shreya Mundra",
            user_source: "registered_user",
            facebook_profile_url: null,
            linkedin_profile_url: null,
            twitter_profile_url: null,
            github_profile_url: null,
            instagram_profile_url: null,
            behance_profile_url: null,
            stack_overflow_profile_url: null,
            dribble_profile_url: null,
            profile_title: null,
            headline: null,
            current_slug: "shreya-mundra",
            certified: false,
            emails: ["shreya.mundra@epaylater.in"],
            image: {
                id: "61a0b115224ed10014051da8",
                url: "https://d3sr7cc30ek8f4.cloudfront.net/images/size:1500x1500/type:cover/prod/61a0b115224ed10014051da8/unnamed.png",
                filepath: "prod/61a0b115224ed10014051da8/unnamed.png",
                content_type: "image/png",
                name: "unnamed.png",
            },
            profile_url: "/profiles/shreya-mundra",
            url: "/profiles/shreya-mundra",
            connected_institute_names: [],
            connected_institute_titles: [],
        },
    },
    {
        id: "61a0d94707e6ca0013dbd03a",
        subject: "Area Sales Manager @ePaylater, Mumbai, New Delhi, Indore",
        title: "Area Sales Manager",
        functional_areas: ["Sales / Business"],
        locations: [
            {
                id: "1275339",
                name: "Mumbai",
            },
            {
                id: "1261481",
                name: "New Delhi",
            },
            {
                id: "1269743",
                name: "Indore",
            },
        ],
        min_experience: 8,
        max_experience: 10,
        created_at: "2021-11-26T18:25:35.091+05:30",
        company: {
            id: "584524030907f408ae0083e6",
            name: "ePaylater",
        },
        normalized_titles: [
            {
                id: "5b8e5b29a9dfce00063cd8ad",
                name: "area sales manager",
            },
        ],
        user: {
            id: "61a0b115224ed10014051da7",
            is_official: null,
            name: "Shreya Mundra",
            user_source: "registered_user",
            facebook_profile_url: null,
            linkedin_profile_url: null,
            twitter_profile_url: null,
            github_profile_url: null,
            instagram_profile_url: null,
            behance_profile_url: null,
            stack_overflow_profile_url: null,
            dribble_profile_url: null,
            profile_title: null,
            headline: null,
            current_slug: "shreya-mundra",
            certified: false,
            emails: ["shreya.mundra@epaylater.in"],
            image: {
                id: "61a0b115224ed10014051da8",
                url: "https://d3sr7cc30ek8f4.cloudfront.net/images/size:1500x1500/type:cover/prod/61a0b115224ed10014051da8/unnamed.png",
                filepath: "prod/61a0b115224ed10014051da8/unnamed.png",
                content_type: "image/png",
                name: "unnamed.png",
            },
            profile_url: "/profiles/shreya-mundra",
            url: "/profiles/shreya-mundra",
            connected_institute_names: [],
            connected_institute_titles: [],
        },
    },
    {
        id: "61a0dbc60c7da0001528d3b0",
        subject: "Business Manager - FI @ePaylater, Mumbai",
        title: "Business Manager - FI",
        functional_areas: ["Sales / Business"],
        locations: [
            {
                id: "1275339",
                name: "Mumbai",
            },
        ],
        min_experience: 4,
        max_experience: 5,
        created_at: "2021-11-26T18:36:14.615+05:30",
        company: {
            id: "584524030907f408ae0083e6",
            name: "ePaylater",
        },
        normalized_titles: [
            {
                id: "5b8e5b3e1193a70007738be5",
                name: "business manager",
            },
        ],
        user: {
            id: "61a0b115224ed10014051da7",
            is_official: null,
            name: "Shreya Mundra",
            user_source: "registered_user",
            facebook_profile_url: null,
            linkedin_profile_url: null,
            twitter_profile_url: null,
            github_profile_url: null,
            instagram_profile_url: null,
            behance_profile_url: null,
            stack_overflow_profile_url: null,
            dribble_profile_url: null,
            profile_title: null,
            headline: null,
            current_slug: "shreya-mundra",
            certified: false,
            emails: ["shreya.mundra@epaylater.in"],
            image: {
                id: "61a0b115224ed10014051da8",
                url: "https://d3sr7cc30ek8f4.cloudfront.net/images/size:1500x1500/type:cover/prod/61a0b115224ed10014051da8/unnamed.png",
                filepath: "prod/61a0b115224ed10014051da8/unnamed.png",
                content_type: "image/png",
                name: "unnamed.png",
            },
            profile_url: "/profiles/shreya-mundra",
            url: "/profiles/shreya-mundra",
            connected_institute_names: [],
            connected_institute_titles: [],
        },
    },
    {
        id: "61a0dcc24e173200139a90b9",
        subject:
            "Cluster Manager - Acquisition @ePaylater, Bangalore, Hyderabad",
        title: "Cluster Manager - Acquisition",
        functional_areas: ["HR & Admin"],
        locations: [
            {
                id: "1277333",
                name: "Bangalore",
            },
            {
                id: "1269843",
                name: "Hyderabad",
            },
        ],
        min_experience: 3,
        max_experience: 7,
        created_at: "2021-11-26T18:40:26.928+05:30",
        company: {
            id: "584524030907f408ae0083e6",
            name: "ePaylater",
        },
        normalized_titles: [
            {
                id: "5b8e5bb2c515cf00075ab752",
                name: "cluster manager",
            },
        ],
        user: {
            id: "61a0b115224ed10014051da7",
            is_official: null,
            name: "Shreya Mundra",
            user_source: "registered_user",
            facebook_profile_url: null,
            linkedin_profile_url: null,
            twitter_profile_url: null,
            github_profile_url: null,
            instagram_profile_url: null,
            behance_profile_url: null,
            stack_overflow_profile_url: null,
            dribble_profile_url: null,
            profile_title: null,
            headline: null,
            current_slug: "shreya-mundra",
            certified: false,
            emails: ["shreya.mundra@epaylater.in"],
            image: {
                id: "61a0b115224ed10014051da8",
                url: "https://d3sr7cc30ek8f4.cloudfront.net/images/size:1500x1500/type:cover/prod/61a0b115224ed10014051da8/unnamed.png",
                filepath: "prod/61a0b115224ed10014051da8/unnamed.png",
                content_type: "image/png",
                name: "unnamed.png",
            },
            profile_url: "/profiles/shreya-mundra",
            url: "/profiles/shreya-mundra",
            connected_institute_names: [],
            connected_institute_titles: [],
        },
    },
    {
        id: "61c463c711f3550015046c5f",
        subject: "Business Manager - Partnerships @ePaylater, Mumbai",
        title: "Business Manager - Partnerships",
        functional_areas: ["Sales / Business"],
        locations: [
            {
                id: "1275339",
                name: "Mumbai",
            },
        ],
        min_experience: 3,
        max_experience: 7,
        created_at: "2021-12-23T17:25:51.275+05:30",
        company: {
            id: "584524030907f408ae0083e6",
            name: "ePaylater",
        },
        normalized_titles: [
            {
                id: "5b8e5b3e1193a70007738be5",
                name: "business manager",
            },
        ],
        user: {
            id: "61a0b115224ed10014051da7",
            is_official: null,
            name: "Shreya Mundra",
            user_source: "registered_user",
            facebook_profile_url: null,
            linkedin_profile_url: null,
            twitter_profile_url: null,
            github_profile_url: null,
            instagram_profile_url: null,
            behance_profile_url: null,
            stack_overflow_profile_url: null,
            dribble_profile_url: null,
            profile_title: null,
            headline: null,
            current_slug: "shreya-mundra",
            certified: false,
            emails: ["shreya.mundra@epaylater.in"],
            image: {
                id: "61a0b115224ed10014051da8",
                url: "https://d3sr7cc30ek8f4.cloudfront.net/images/size:1500x1500/type:cover/prod/61a0b115224ed10014051da8/unnamed.png",
                filepath: "prod/61a0b115224ed10014051da8/unnamed.png",
                content_type: "image/png",
                name: "unnamed.png",
            },
            profile_url: "/profiles/shreya-mundra",
            url: "/profiles/shreya-mundra",
            connected_institute_names: [],
            connected_institute_titles: [],
        },
    },
];
