import { useTheme } from "@emotion/react";
import { withStyles, withTheme } from "@mui/styles";
import React from "react";
import classNames from "classnames";
import { Card, CardContent, CardMedia } from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Health2022 from "../../assets/Insights_from_syneos/2022_Health.png";
import Dealmakers2022 from "../../assets/Insights_from_syneos/2022_Dealmakers.png";
import SyneosHealth from "../../assets/Insights_from_syneos/Syneos_health.png";

const styles = (theme) => {
    return {
        insightsContainer: {
            margin: "auto",
            padding: "80px 0px",
            maxWidth: "1100px",
        },
        insightCardsContainer: {
            marginTop: "25px",
            gap: "40px",
            flexWrap: "wrap",
        },
        cardContent: {
            padding: "16px 0px 0px",
            backgroundColor: "transparent",
        },
    };
};

const Insights = (props) => {
    const { classes } = props;
    const theme = useTheme();
    return (
        <div className={classes.insightsContainer}>
            <div
                className="default_flex_container"
                style={{ justifyContent: "space-between" }}
            >
                <div
                    className="text_title"
                    style={{ fontSize: 32, fontWeight: 800 }}
                >
                    Insights From Syneos
                </div>
                <div
                    className="text_subtitle_big"
                    style={{ color: theme.palette.primary.main }}
                >
                    <span
                        style={{ verticalAlign: "middle", marginRight: "2px" }}
                    >
                        See All
                    </span>
                    <ArrowForwardIcon
                        style={{ verticalAlign: "middle", width: "20 px" }}
                    />
                </div>
            </div>

            <div
                className={classNames(
                    classes.insightCardsContainer,
                    "default_flex_container"
                )}
                style={{alignItems: 'flex-start'}}
            >
                <div style={{ borderRadius: 4, maxWidth: 340 }}>
                    <img src={Health2022} width="100%"></img>
                    <div className={classes.cardContent}>
                        <div className="text_title">
                            2022 Health trends: Insights for Industry Change
                            Agents
                        </div>
                        <div
                            className="text_caption"
                            style={{ margin: "4px 0px 8px" }}
                        >
                            Dec 26 ‘22
                        </div>
                        <div
                            className="text_body_big_light"
                            style={{ marginTop: 4, fontWeight: 600 }}
                        >
                            After a year of absolute uncertainty,
                            micro-predictions and near constant recalculations,
                            the picture of our world an...
                        </div>
                        <a
                            href="/"
                            className="text_link_big"
                            style={{
                                display: 'block',
                                marginTop: 4,
                                color: theme.palette.primary.main,
                            }}
                        >
                            Read More
                        </a>
                    </div>
                </div>

                <div style={{ borderRadius: 4, maxWidth: 340 }}>
                    <img src={Dealmakers2022} width="100%"></img>
                    <div className={classes.cardContent}>
                        <div className="text_title">
                            2022 Dealmakers’ Intentions
                        </div>
                        <div
                            className="text_caption"
                            style={{ margin: "4px 0px 8px" }}
                        >
                            Jan 11 ‘22
                        </div>
                        <div
                            className="text_body_big_light"
                            style={{ marginTop: 4, fontWeight: 600 }}
                        >
                            As industry dynamics shift, smaller biotechnology
                            companies will continue to drive dealmaking in 2022,
                            according to t...
                        </div>
                        <a
                            href="/"
                            className="text_link_big"
                            style={{
                                display: 'block',
                                marginTop: 4,
                                color: theme.palette.primary.main,
                            }}
                        >
                            Read More
                        </a>
                    </div>
                </div>

                <div style={{ borderRadius: 4, maxWidth: 340 }}>
                    <img src={SyneosHealth} width="100%"></img>
                    <div className={classes.cardContent}>
                        <div className="text_title">
                            Syneos Health Podcast: Business Continuity Through
                            Strategic O...
                        </div>
                        <div
                            className="text_caption"
                            style={{ margin: "4px 0px 8px" }}
                        >
                            Jan 5 ‘22
                        </div>
                        <div
                            className="text_body_big_light"
                            style={{ marginTop: 4, fontWeight: 600 }}
                        >
                            In this episode of the Syneos Health Podcast, our
                            experts discuss ways to navigate business continuity
                            through
                        </div>
                        <a
                            href="/"
                            className="text_link_big"
                            style={{
                                display: 'block',
                                marginTop: 4,
                                color: theme.palette.primary.main,
                            }}
                        >
                            Read More
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(withTheme(Insights));
