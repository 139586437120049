import { useTheme, withStyles, withTheme } from '@mui/styles'
import Card from '@mui/material/Card';
import { CardContent, CardActionArea, CardMedia, Typography } from '@mui/material';
import ClinicalDevelopment from '../../assets/services_we_offer/clinicaldevelopment.png';
import Commercialization from '../../assets/services_we_offer/Commercialization.png';
import Consulting from '../../assets/services_we_offer/Consulting.png';
import classNames from 'classnames';

const styles = theme => {
    return{
        servicesContainer:{
            maxWidth: '1100px',
            margin: '80px auto',
            padding: '0px 24px',
            [theme.breakpoints.down('sm')]:{
                margin: '40px auto'
            }
        },
        h1:{
            fontWeight: 800,
            fontSize: 32,
        },
        cardsContainer:{
            marginTop: 24,
            gap: '40px',
            flexWrap: 'wrap'
        },
        cardHeader: {
            fontSize: 24,
            fontWeight: 700,
            color: theme.palette.primary.main
        },
    }
}

const ServicesOffered = (props) => {
    const {classes} = props
    return (
    <div className={classes.servicesContainer}>
        <div className="text_super_big_title" style={{color: props.theme.palette.primary.main}}>About Syneos Health</div>
        <div className={classNames(classes.cardsContainer, "default_flex_container")}>
            <Card sx={{maxWidth: 340}} style={{
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16)',
                borderRadius: 4,
                cursor: 'pointer',
            }}>
                <CardActionArea href="https://www.syneoshealth.com/solutions/clinical-development">
                  <CardMedia component="img" height="180" image={ClinicalDevelopment} />
                  <CardContent style={{padding: '16px 24px 24px'}}>
                      <div className={classes.cardHeader}>
                          Clinical Development
                      </div>
                      <div className='text_body_big_light' style={{marginTop: 4, fontWeight: 600}}>
                          Our fit-for-purpose CRO solutions are tailored to your product and requirements.
                      </div>
                  </CardContent>
                </CardActionArea>
            </Card>

            <Card sx={{maxWidth: 340}} style={{
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16)',
                borderRadius: 8,
                cursor: 'pointer'
            }}>
              <CardActionArea href="https://www.syneoshealth.com/solutions/commercialization">
                <CardMedia component="img" height="180" image={Commercialization} />
                <CardContent style={{padding: '16px 24px 24px'}}>
                    <div className={classes.cardHeader}>
                        Commercialization
                    </div>
                    <div className='text_body_big_light' style={{marginTop: 4, fontWeight: 600}}>
                        We improve brand performance by leveraging data and insights and deep therapeutic expertise.
                    </div>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card sx={{maxWidth: 340}} style={{
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16)',
                borderRadius: 4,
                cursor: 'pointer'
            }}>
              <CardActionArea href="https://www.syneoshealth.com/solutions/consulting">
                <CardMedia component="img" height="180" image={Consulting} />
                <CardContent style={{padding: '16px 24px 24px'}}>
                    <div className={classes.cardHeader}>
                        Consulting
                    </div>
                    <div className='text_body_big_light' style={{marginTop: 4, fontWeight: 600}}>
                        Our consulting practitioners span the entire product development and delivery continuum.
                    </div>
                </CardContent>
              </CardActionArea>
            </Card>
        </div>
    </div>
  )
}

export default withStyles(styles)(withTheme(ServicesOffered))
