import logo from './assets/Logo.png'
import favicon from './assets/favicon.png'

const metadata = {
    name: "{AlmaConnect}",
    pageTitle: "{AlmaConnect} Alumni network | AlmaConnect",
    networkBaseUrl: "https://syneos.almaconnect.com/",
    logo: logo,
    favicon: favicon,
    header_navigation_url: "https://www.syneoshealth.com/",
    mainMenuItems: [
      {
        name: "News",
        url: "/contributions/sneak_peek",
      },
      {
        name: "Careers",
        url: "/jobs",
      },
      {
        name: "HomeComings",
        url: "/memories"
      },
      {
        name: "Engage",
        url: "/directory/filter",
      },
    ],
    bannerImages: ["{banner1Import}", "{banner2Import}"],
    bannerText: {
      primaryText: "With over <b>{4,500}+</b> alumni across the globe, {AlmaConnectians} are flagbearers of excellence in the field of strategic marketing and communication",
      secondaryText: "This Alumni portal looks to help them reconnect with their Alma Mater"
    },
    networkAndLogin: {
      enabled: true,
      collage: "{collage}",
    },
    alumniMap: {
      enabled: false,
      image: "{alumniChaptersImageImport}",
    },
    alumniNews: {
      enabled: false,
    },
    video:{
      url: "https://player.vimeo.com/video/716410636?h=2e385ae900&badge=0&autopause=0&player_id=0&app_id=58479",
      title: 'Syneos Health Biopharmaceutical Acceleration Model'
    },
    twitterFeed: {
      enabled: false,
      screenName: "almaconnect",
      displayName: "AlmaConnect",
      logo: "{twitterLogoImport}",
    },
    upcomingEvents: {
      enabled: false,
    },
    recentMemories: {
      enabled: false,
    },
    footer_address_name: 'Syneos Health® (Nasdaq:SYNH) is the only fully integrated biopharmaceutical solutions organization purpose-built to accelerate customer success. ',
    footer_links: [
        {url: 'https://syneos.almaconnect.com/directory/filter', name: 'Alumni Directory'},
        {url: 'https://syneos.almaconnect.com/event-calender', name: 'Events'},
        {url: 'https://syneos.almaconnect.com/jobs', name: 'Jobs'},
        {url: 'https://syneos.almaconnect.com/jobs', name: 'Communities'},
        {url: 'https://syneos.almaconnect.com/jobs', name: 'Alumni Benefits'},
    ],
    distinguishedAlumni: {
      enabled: false,
      nominationEmail: "coordinator@almaconnect.com",
      data: [
        {
          name: "Alum Name",
          picture: "{alumImageImport}",
          description: "Batch, Designation, Location String",
        },
      ],
    },
  
    theme: {
      colors: {
        charcoalGrey: "#3a3f42",
        coolGrey: "#b6b9bb",
        turquoise: "#00c4b5",
        lightTurquoise: "#ebfbf9",
        battleshipGrey: "#707274",
        rosyPink: "#ef5d60",
        paleGrey: "#eef0f2",
        lightGrey: "#e7eceb",
        border: "#f1f0f0",
      },
    }
  }
  
  export default metadata;
  