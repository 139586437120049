import logo from './logo.svg';
import './App.scss';
import Root from './components/root/Root';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material';
const primary = "rgba(235, 51, 0, 1)";
const secondary = "#CD2D00";//"rgba(235, 51, 0, 0.8)";
const theme = createTheme({
  palette: {
    primary:{
      main: primary
    },
    secondary:{
      main: secondary
    }
  },
  typography: {
    fontFamily: ["Nunito Sans", "Roboto", "Helvetica", "Arial", "sans-serif"],
    useNextVariants: true,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root:{
            borderRadius: 3,
            color: '#fff',
            background: primary,
            padding: '8px 24px',
            textTransform: 'capitalize',
            textAlign: 'center',
            fontWeight: 700,
            fontSize: 14,
            fontFamily: ["Nunito Sans", "Roboto", "Helvetica", "Arial", "sans-serif"],
            border: 'none',
            "&:hover":{
              background: secondary,
              cursor: 'pointer',
            },
            "&:disabled, &.disabled, &[disabled='disabled']":{
              background: '#acc4bf',
              opacity:0.2,
              cursor: 'default',
            }
        }
      },
    },
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Root />
    </ThemeProvider>
  );
}

export default App;
