import { ClassNames } from "@emotion/react";
import { withStyles } from "@mui/styles";
import { maxWidth } from "@mui/system";
import classNames from "classnames";
import React from "react";
import defaultMetadata from "../../defaultMetadata";
import WelcomeImage from "../../assets/WelcomeImage.png"

const styles = (theme) => {
    return {
        welcomePostContainer: {
            margin: "auto",
            maxWidth: 1076,
            padding: "72px 0px",
            flexWrap: 'wrap',
            gap: "58px",
            [theme.breakpoints.down(1080)]:{
                justifyContent: 'center',
                padding: "32px 24px 32px 24px",
                gap: "38px",
            }
        },
        welcomeImage: {
            width: 540,
            height: 303,
            background: "#FAFAFA",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.16)",
            borderRadius: 4,
            [theme.breakpoints.down('sm')]:{
                width: 312,
                height: 208,
            }
        },
        welcomeContent: {
            padding: "16px 0px",
            maxWidth: 440,
            // marginLeft: 58,
        },
    };
};

const WelcomePost = (props) => {
    const { classes } = props;
    return (
        <div
            className={classNames(
                classes.welcomePostContainer,
                "default_flex_container"
            )}
        >
            <div className={classes.welcomeImage}>
                {/* <iframe
                    width="100%"
                    height="100%"
                    src={defaultMetadata.video.url}
                    title={defaultMetadata.video.title}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe> */}
                <img src={WelcomeImage} style={{width: "100%", height: "100%"}} alt=""/>
            </div>
            <article className={classes.welcomeContent}>
                <div className="text_title" style={{fontWeight: '800'}}>Welcome Note</div>
                <div style={{ marginTop: 10 }} className="text_body_big">
                    <div>
                        We welcome you all to the Syneos Health Consulting alumni network and wholeheartedly encourage you to join us. We all know the power of networks in the digital economy.
                    </div>
                    <div style={{ marginTop: 16 }}>
                        Connections make us strong and give us the ability to see the world through the unique perspectives of our connected peers. We are very proud that thousands of women and men around the world shape their own future with Syneos Health and the Syneos Health Consulting Team.
                    </div>                    
                </div>
            </article>
        </div>
    );
};

export default withStyles(styles, {withTheme: true})(WelcomePost);
