import { withTheme } from "@emotion/react";
import { withStyles } from "@mui/styles";
import React from "react";
import Header from '../header/Header.jsx';
import Footer from '../footer/footer.jsx';
import About from '../snippets/about.jsx';
import Banner from "../banner/Banner.jsx";
import SearchAndNetwork from "../search_and_network/SearchAndNetwork.jsx";
import WelcomePost from "../welcome_post/WelcomePost.jsx";
import ServicesOffered from "../services_offered/ServicesOffered.jsx";
import JobBox from "../find_jobs/JobBox.jsx";
import Insights from "../insights/Insights.jsx";
import DiscoverPerks from "../discover_perks/DiscoverPerks.jsx";

const styles = (theme) => {
  return {
    root:{
      width: "100%",
      margin: "auto",
      backgroundColor: '#fff',
    }
}}
const Root = (props) => {
    return (
        <div className={props.classes.root}>
          <Header />
          <Banner />
          <WelcomePost />
          <DiscoverPerks />
          {/* <SearchAndNetwork /> */}
          {/* <About/> */}
          <ServicesOffered />
          {/* <JobBox /> */}
          {/* <Insights /> */}
          {/* <DiscoverPerks /> */}
          {/* <About/> */}
          <Footer />
        </div>
    );
};

export default withStyles(styles)(withTheme((Root)));
