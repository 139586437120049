// import URL from 'url';

export function acConcat({values, baseUrl}) {
  const prepareLink = function(value) {
    if (!value) return;
    if (typeof(value) != "object") return value.toString();
    if (!value.name) return;
    if (!value.url) return value.name;

    const url = new URL(value.url, baseUrl).href;
    return '<a href="' + url + '" class="ac_link">' + value.name + '</a>';
  };

  const concat = (v1, seperator, v2) => {
    return [prepareLink(v1), prepareLink(v2)].filter(String).filter(Boolean).join(seperator);
  };

  while(values.length > 1){
    let objects = values.splice(0, 3);
    let output = concat(objects[0], objects[1], objects[2]);
    values.splice(0, 0, output);
  }
  return values[0];
}

export function truncate(str, max_len, min_len){
  str = str || "";
  if (str.length > max_len){
    let space_index = str.indexOf(" ",min_len);
    if (space_index === -1) space_index=str.lastIndexOf(" ");
    return str.slice(0, space_index)+'...';
  }else{
    return str;
  }
};
