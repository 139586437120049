import React, { useCallback } from 'react';

// import URL from 'url';

import Grid from '@mui/material/Grid';
import { withStyles } from "@mui/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import classnames from "classnames";
import defaultMetadata from "../../defaultMetadata";
import { useTheme, withTheme } from '@emotion/react';
import UpcomingEvents from "./upcoming_events.jsx";
import story1 from '../../assets/alumni_stories/story_1.png'
import story2 from '../../assets/alumni_stories/story_2.png'
import story3 from '../../assets/alumni_stories/story_3.png'
import WhatsImage from "../../assets/whats_happening.png";
import biopharma from '../../assets/about_syneos/biopharma.png'
import collaboration from '../../assets/about_syneos/collaboration.png'
import AlumniSpotlight from './AlumniSpotlight'
import memory1 from '../../assets/memories/1.png'
import memory2 from '../../assets/memories/2.png'
import memory3 from '../../assets/memories/3.png'


const DropItem = () => <></>

const styles = (theme) =>{
    return {
        whatsContainer: {
            width: "100%",
            position: "relative",
        },
        whatsImage:{
            width: '100%',
            height: 340,
            borderBottom: `16px solid ${theme.palette.primary.main}`
        },
        whatsContent: {
            position: 'absolute',
            top: 64,
            left: 92,
            right: 92,
            maxWidth: '1100px',
            margin: 'auto'
        },
        aboutContent: {
            position: 'absolute',
            top: 40,
            left: 40,
            right: 40,
            bottom: 40,
            margin: 'auto'
        },
        button:{
            padding: '18px 35px',
            color: 'white',
            border: '1px solid white',
            boxSizing: 'border-box',
            transition: 'all 400ms ease',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            width: '160px',
            height: '48px',
            "&:hover":{
                backgroundColor: '#EB3300',
                borderColor: '#EB3300',
            }
        },
        memoryTitle:{
            color: theme.palette.primary.main
        }
    }
};


const About = (props) => {
    const theme = useTheme();

    const alumni_stories = () => {
        return (
            <div style={{margin: '80px auto 24px', maxWidth: '1100px', padding: '0px 24px'}}>
                <div className="default_flex_container" style={{ justifyContent: "space-between" }}>
                    <div className="text_title" style={{ fontSize: 32, fontWeight: 800 }}>
                        Alumni Stories
                    </div>
                    <div className="text_subtitle_big" style={{ color: theme.palette.primary.main }}>
                    <span style={{ verticalAlign: "middle", marginRight: "2px" }}>
                        See All
                    </span>
                    <ArrowForwardIcon style={{ verticalAlign: "middle", width: "20 px" }}/>
                    </div>
                </div>
                <div className="text_title_small" style={{paddingTop: '16px'}}>Inspirational series our alumni share, in their own words.</div>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '40px', paddingTop: '24px'}}>
                    <div className="story_box">
                        <img className="story_image" src={story1} alt="logo" />
                        <div className="text_title_small">In search of the world’s most magnificent mountaineering – Charles Sherwood</div>
                        <div className="text_body">In her mission for more equitable health care, Dr. Marshala Lee MD’11 is using every tool..</div>
                        <div className="text_body_link" style={{paddingTop: '12px', color: '#EB3300', fontWeight: 'bold'}}>Read more</div>
                    </div>
                    <div className="story_box">
                        <img className="story_image" src={story2} alt="logo" />
                        <div className="text_title_small">Health care and haircuts: Bringing preventative measures to the neighborhood</div>
                        <div className="text_body">In her mission for more equitable health care, Dr. Marshala Lee MD’11 is using every tool..</div>
                        <div className="text_body_link" style={{paddingTop: '12px', color: '#EB3300', fontWeight: 'bold'}}>Read more</div>
                    </div>
                    <div className="story_box">
                        <img className="story_image" src={story3} alt="logo" />
                        <div className="text_title_small">A passion for learning</div>
                        <div className="text_body">Alexandros Pamnani shares how he went from dropping out of school to working fo..</div>
                        <div className="text_body_link" style={{paddingTop: '12px', color: '#EB3300', fontWeight: 'bold'}}>Read more</div>
                    </div>
                </div>
            </div>
        );
    }

    const memories = () => {
        return (
            <div style={{background: '#3A3F42'}}>
                <div style={{padding: '50px 24px', maxWidth: '1100px', margin: 'auto'}}>
                    <div className="default_flex_container" style={{ justifyContent: "space-between" }}>
                        <div className={classes.memoryTitle+' text_super_big_title'}>
                            Recent Memories
                        </div>
                        <div className="text_subtitle_big" style={{ color: theme.palette.primary.main }}>
                            <span style={{ verticalAlign: "middle", marginRight: "2px" }}>
                                See All
                            </span>
                            <ArrowForwardIcon style={{ verticalAlign: "middle", width: "20 px" }}/>
                        </div>
                    </div>
                    <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '40px', marginTop: '16px'}}>
                        <div>
                            <img
                                style={{width: '100%'}}
                                src={memory1}
                                alt="image"
                            />
                            <div className="text_title_small" style={{color: '#fff', marginTop: '16px'}}>Alumni Re-union ‘20</div>
                        </div>
                        <div>
                            <img
                                style={{width: '100%'}}
                                src={memory2}
                                alt="image"
                            />
                            <div className="text_title_small" style={{color: '#fff', marginTop: '16px'}}>DIA ‘18</div>
                        </div>
                        <div>
                            <img
                                style={{width: '100%'}}
                                src={memory3}
                                alt="image"
                            />
                            <div className="text_title_small" style={{color: '#fff', marginTop: '16px'}}>Regional meetup</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const whats_happening = () => {
        return (
            <div className={classes.whatsContainer}>
                <img
                    className={classes.whatsImage}
                    src={WhatsImage}
                    alt="image"
                />
                <div className={classes.whatsContent}>
                    <div className="default_flex_container" style={{ justifyContent: "space-between" }}>
                        <div className="text_super_big_title" style={{color: '#fff'}}>What’s happening <span style={{fontWeight: '500'}}>at Syneos</span></div>
                        <div className="text_subtitle_big" style={{ color: '#fff' }}>
                            <span style={{ verticalAlign: "middle", marginRight: "2px", textDecoration: 'underline' }}>
                                Discover Syneos Health
                            </span>
                        </div>
                    </div>
                    <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '40px', paddingTop: '24px'}}>
                        <div>
                            <div className="text_title" style={{color: '#fff'}}>Syneos Health and Aetion Partner to Offer RWE Solutions to Advance Drug Development and Commercialization</div>
                            <div className="text_subtitle_big" style={{ color: '#fff', marginTop: '16px', textDecoration: 'underline'}}>
                                <span style={{ verticalAlign: "middle", marginRight: "2px" }}>Read the News</span>
                                <ArrowForwardIcon style={{ verticalAlign: "middle", width: "20 px" }}/>
                            </div>
                        </div>
                        <div>
                            <div className="text_title" style={{color: '#fff'}}>Ukraine Conflict Resource Center</div>
                            <div className="text_subtitle_big" style={{ color: '#fff', marginTop: '16px', textDecoration: 'underline' }}>
                                <span style={{ verticalAlign: "middle", marginRight: "2px" }}>Visit the Resource center</span>
                                <ArrowForwardIcon style={{ verticalAlign: "middle", width: "20 px" }}/>
                            </div>
                        </div>
                        <div>
                            <div className="text_title" style={{color: '#fff'}}>Syneos Health Colleagues Choose to Challenge Gender Disparity on International Women’s Day and Beyond</div>
                            <div className="text_subtitle_big" style={{ color: '#fff', marginTop: '16px', textDecoration: 'underline' }}>
                                <span style={{ verticalAlign: "middle", marginRight: "2px" }}>Watch the Video</span>
                                <ArrowForwardIcon style={{ verticalAlign: "middle", width: "20 px" }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const about_inst = () => {
        return(
            <div style={{margin: '80px auto 24px', maxWidth: '1100px', padding: '0px 24px'}}>
                <div className="text_super_big_title" style={{color: props.theme.palette.primary.main}}>About Syneos</div>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '32px', paddingTop: '24px'}}>
                    <div className={classes.whatsContainer}>
                        <img
                            style={{width: '100%'}}
                            src={biopharma}
                            alt="image"
                        />
                        <div className={classes.aboutContent}>
                            <div className="text_super_big_title" style={{color: '#fff'}}>Biopharmaceutical Acceleration Model®</div>
                            <div className="text_title_small" style={{color: '#fff', paddingTop: '16px'}}>At Syneos Health, clinical and commercial live under the same roof and constantly share real world knowledge and insights that lead to getting the job done better, smarter and faster. </div>
                            <div className={classes.button} style={{marginTop: '48px'}}>Learn More</div>
                        </div>
                    </div>
                    <div className={classes.whatsContainer}>
                        <img
                            style={{width: '100%'}}
                            src={collaboration}
                            alt="image"
                        />
                        <div className={classes.aboutContent}>
                            <div className="text_super_big_title" style={{color: '#fff'}}>Syneos Collaborations</div>
                            <div className="text_title_small" style={{color: '#fff', paddingTop: '16px'}}>At Syneos Health, clinical and commercial live under the same roof and constantly share real world knowledge and insights that lead to getting the job done better, smarter and faster. </div>
                            <div className={classes.button} style={{marginTop: '94px'}}>Learn More</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const {classes} = props;
    return (
        <div>
            {/* <div className="contentContainer">
                <div style={{padding: 12}}>
                    <Grid container spacing={2} justifyContent="space-between" style={{padding: '44px 0px'}}>
                        <Grid item sm={12} md={6} >
                            <AlumniSpotlight metadata={defaultMetadata}/>
                        </Grid>
                        <Grid item sm={12} md={6} >
                            <UpcomingEvents metadata={defaultMetadata} style={{height: "100%"}} />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div style={{width: '634px', border: '1px solid #EB3300', margin: 'auto'}}></div>
            <div>{alumni_stories()}</div>
            <div>{memories()}</div>
            <div>{whats_happening()}</div> */}
            <div>{about_inst()}</div>
        </div>
    );

}

export default withStyles(styles, {withTheme: true})(withTheme((About)));