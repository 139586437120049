import { withTheme } from "@emotion/react";
import { withStyles } from "@mui/styles";

import React from "react";
import BannerImage from "../../assets/BannerImage.png";
const styles = (theme) => {
    return {
        bannerContainer: {
            width: "100%",
            position: "relative",
            backgroundImage: `url(${BannerImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            padding: '100px 0px',
            [theme.breakpoints.down(721)]: {
                padding: '77px 0px',
            }
        },
        bannerImage:{
            width: '100%',
            borderBottom: `16px solid ${theme.palette.primary.main}`
        },
        bannerContent:{
            margin: "auto",
            padding: '0px 0px 0px 113px',
            [theme.breakpoints.down('721')]:{
                padding: '0px 24px',
            },
            "& h2": {
                color: 'white',
                fontSize: 30,
                fontWeight: 400,
                marginBottom: 4,
                [theme.breakpoints.down('721')]:{
                    fontSize: 20
                }
            },
            "& h1": {
                color: 'white',
                fontSize: 64,
                fontWeight: 800,
                maxWidth: 818,
                margin: 0,
                [theme.breakpoints.down('721')]:{
                    fontSize: 32
                }
            },
            "& h4":{
                color: 'white',
                fontSize: 20,
                fontWeight: 400,
                maxWidth: 694,
                marginTop: 20,
                [theme.breakpoints.down('721')]:{
                    fontSize: 16
                }
            }
        },
        smolOrangeBar:{
            width: '88px',
            height: '4px',
            backgroundColor: '#FF671F',
        },
    };
};

const Banner = (props) => {
    const { classes } = props;
    return (
        <div className={classes.bannerContainer}>
            {/* <img
                className={classes.bannerImage}
                src={BannerImage}
                alt=""
            /> */}
            <div className={classes.bannerContent}>
                <h2>
                    Welcome to the
                </h2>
                <h1>
                    Syneos Health Consulting Alumni Network
                </h1>
                <div className={classes.smolOrangeBar}></div>
                <h4 className={classes.bannerDescription}>
                    Stay connected, maintain relationships, take part in Syneos Health Consulting alumni events and seize networking opportunities.
                </h4>
            </div>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(withTheme(Banner));
