import { withTheme } from "@emotion/react";

import React from "react";
import DiscoverPerksImg from "../../assets/DiscoverPerks.png";
import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import metadata from "../../defaultMetadata";
import Arrow from "../../assets/Arrow.png";
import classNames from "classnames";

const styles = (theme) => {
    return {
        discoverPerksContainer: {
            width: "100%",
            position: "relative",
            backgroundImage: `url(${DiscoverPerksImg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',

        },
        DiscoverPerksImage: {
            width: "100%",
            // height: 400,
        },
        DiscoverPerksContent: {
            margin: 'auto',
            maxWidth: '960px',
            width: '100%',
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            flexWrap: "wrap",
            padding: '107px 0px',
            [theme.breakpoints.down(1020)]:{
                padding: '107px 24px',
                width: 'auto'
            },
            [theme.breakpoints.down(960)]:{
                flexDirection: 'column'
            },
            [theme.breakpoints.down('sm')]:{
                padding: '40px 24px'
            },
        },
        headingMain:{
            fontSize: "40px",
            fontWeight: 800,
            lineHeight: '40px',
            color: "white",
            [theme.breakpoints.down('sm')]:{
                fontSize: "32px",                
            },
        },
        headingSecondary:{
            fontSize: "16px",
            fontWeight: 600,
            color: "white",
        },

        loginButton: {
            padding: "13px 115px",
            fontSize: "18px",
        },
        leftContent:{
            maxWidth: '370px',
            [theme.breakpoints.down(960)]:{
                maxWidth: 'none',
                textAlign: 'center',
                marginBottom: '27px'
            },
            [theme.breakpoints.down('sm')]:{
                marginBottom: 0
            },
        },
        rightContent:{
            
        },

        buttonsGrid: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "24px",
            [theme.breakpoints.down("sm")]: {
                gridTemplateColumns: "1fr",
                margin: "24px auto 0px",
            },
        },

        gridButtonTemporary: {
            minHeight: 80,
            minWidth: 240,
            color: 'white',
            border: '1px solid white',                 
            boxSizing: 'border-box',
            transition: 'all 400ms ease',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',   
            flexDirection: 'column' ,
            cursor: "default"
            // "&:hover":{
            //     backgroundColor: '#FF9E1B',
            //     borderColor: '#FF9E1B',
            //     cursor: 'default'
            // }        
        },
        smallText:{
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: 'white'
        }

    };
};

const DiscoverPerks = (props) => {
    const { classes } = props;
    return (
        <div className={classes.discoverPerksContainer}>
            {/* <img
                className={classes.DiscoverPerksImage}
                src={DiscoverPerksImg}
                alt=""
            /> */}
            <div className={classes.DiscoverPerksContent}>
                <div className={classes.leftContent}>
                    <h1 className={classes.headingMain}>Discover your Network</h1>
                    <h1 className={classes.headingSecondary} style={{marginTop: 20}}>
                    Explore the opportunities to connect with Syneos Health Consulting alumni
                    </h1>
                    <Button
                        href={metadata.networkBaseUrl}
                        style={{
                            padding: "16px 35px 16px 40px",
                            fontWeight: 700,
                            fontSize: "18px",
                            marginTop: "22px",
                        }}
                    >
                        <span>Login / Signup</span>
                        <img
                            src={Arrow}
                            style={{
                                width: "24px",
                                marginLeft: 15
                            }}
                        />
                    </Button>
                </div>
                <div className={classes.rightContent}>
                    {/* <h1 className="heading-secondary" style={{marginBottom: 20}}>
                        Access Online Resources & services available for you
                    </h1> */}
                    <div className={classNames(classes.buttonsGrid, "text_title")}>
                        <div className={classNames(classes.gridButtonTemporary)}>                        
                            <div className={classNames(classes.smallText)}>
                                Access
                            </div>
                            <div>
                                Alumni Directory
                            </div>
                            {/* <img src={Arrow} alt="" style={{width: 24}}/> */}
                        </div>
                        <div className={classNames(classes.gridButtonTemporary)}>                        
                            <div className={classNames(classes.smallText)}>
                                Explore
                            </div>
                            <div>
                                Career Opportunities
                            </div>
                            {/* <img src={Arrow} alt="" style={{width: 24}}/> */}
                        </div>
                        <div className={classNames(classes.gridButtonTemporary)}>
                            <div className={classNames(classes.smallText)}>
                                Share
                            </div>
                            <div>
                                News & Updates
                            </div>
                            {/* <img src={Arrow} alt="" style={{width: 24}}/>                                                         */}
                        </div>
                        <div className={classNames(classes.gridButtonTemporary)}>
                            <div className={classNames(classes.smallText)}>
                                Attend
                            </div>
                            <div>
                                Events
                            </div>
                            {/* <img src={Arrow} alt="" style={{width: 24}}/>                                                         */}
                        </div>
                        <div className={classNames(classes.gridButtonTemporary)}>
                            <div className={classNames(classes.smallText)}>
                                Post
                            </div>
                            <div>
                                Memories
                            </div>
                            {/* <img src={Arrow} alt="" style={{width: 24}}/>                                                         */}
                        </div>
                        <div className={classNames(classes.gridButtonTemporary)}>
                            <div className={classNames(classes.smallText)}>
                                Get
                            </div>
                            <div>
                                Mentored
                            </div>
                            {/* <img src={Arrow} alt="" style={{width: 24}}/>                                                         */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(DiscoverPerks);
